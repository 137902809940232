import * as Sentry from '@sentry/nuxt';

export default defineNuxtRouteMiddleware(async () => {
  const { $firebase, $posthog } = useNuxtApp();

  await $firebase.authStateReady();
  const user = $firebase.currentUser;

  if (user) {
    return;
  } else {
    $firebase.signOut();
    Sentry.setUser(null);
    $posthog.reset();
    return navigateTo('/auth/login');
  }
});
